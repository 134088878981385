<template>
  <nav class="navbar profile-navbar navbar-expand-lg px-0">
    <div class="w-100 px-2 profile-navbar-site">
      <!--      <div class="navbar-brand float-left">{{ title }}</div>-->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mx-0 dropdown d-none">
            <el-dropdown trigger="click" class="nav-link dropdown-toggle special-view-button">
              <i class="special-view-icon"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-circle-check">Action 1</el-dropdown-item>
                <el-dropdown-item icon="el-icon-circle-check">Action 2</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li class="nav-item mx-0 dropdown">
            <el-dropdown @command="switchLocale" trigger="click" class="nav-link dropdown-toggle change-locale-button">
              <span class="el-dropdown-link">{{ $i18n.locale }}</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="kz">Казахский</el-dropdown-item>
                <el-dropdown-item command="ru">Русский</el-dropdown-item>
                <!--                <el-dropdown-item command="en">Английский</el-dropdown-item>-->
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li class="nav-item mx-0 dropdown d-none">
            <el-dropdown trigger="click" class="nav-link dropdown-toggle change-locale-button">
              <span class="el-dropdown-link"><i class="notification-icon"></i></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>Notification 1</el-dropdown-item>
                <el-dropdown-item>Notification 2</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li class="nav-item exit-link">
            <a @click.prevent="logoutMethod" class="nav-link cursor-pointer">{{ $t('profile.logout') }}</a>
          </li>
          <li class="nav-item">
            <a href="/"><img src="/images/1000-BALA-logo.svg" width="44"></a>
            <!--              <router-link to="/" tag="a"><img src="images/profile/logo.svg" width="44"></router-link>-->
          </li>
        </ul>
      </div>
    </div>


    <div class="profile-navbar-mobile w-100">
      <div class="nav-logo">
        <a href="/"><img src="/images/navbar-new.svg" width="60"></a>
      </div>
      <div class="menu-block">
        <ul class="navbar-nav">
          <li class="nav-item mx-0 dropdown">
            <el-dropdown @command="switchLocale" trigger="click" class="nav-link dropdown-toggle change-locale-button">
              <span class="el-dropdown-link">{{ $i18n.locale }}</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="kz">Казахский</el-dropdown-item>
                <el-dropdown-item command="ru">Русский</el-dropdown-item>
                <!--                <el-dropdown-item command="en">Английский</el-dropdown-item>-->
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
      <div class="menu-block d-none">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mx-0 dropdown">
            <el-dropdown trigger="click" class="nav-link dropdown-toggle special-view-button">
              <i class="special-view-icon"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-circle-check">Action 1</el-dropdown-item>
                <el-dropdown-item icon="el-icon-circle-check">Action 2</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
      <div class="menu-block d-none">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mx-0 dropdown">
            <el-dropdown trigger="click" class="nav-link dropdown-toggle change-locale-button">
              <span class="el-dropdown-link"><i class="notification-icon"></i></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>Notification 1</el-dropdown-item>
                <el-dropdown-item>Notification 2</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
      <div class="modal-menu-btn" data-toggle="modal" data-target="#modalMenu"
           onclick="document.getElementById('id01').style.display='block'">
        <img src="/images/collapse-icon.svg" width="40">
      </div>
    </div>

    <div id="id01" class="w3-modal">
      <div class="w3-modal-content">
        <div class="w3-container">
          <button onclick="document.getElementById('id01').style.display='none'" class="fa closeIcon btn-back-menu"
                  aria-hidden="true">
            <img src="/images/arrow_back-menu.png" alt="">
          </button>
          <div class="sidebar sideBarMobile profile-sidebar">
            <div class="profile">
              <div class="avatar" :style="`background-image: ${avatar ? `url(${avatar})` : 'url(/images/intersect.svg)'}`"></div>
              <div class="username">{{ user.first_name }} {{ user.last_name }}</div>
              <div v-if="user.education" class="role text-center">
                {{ (user.education.status == 0) ? $t('sidebar.member') : '' }}
              </div>
            </div>
            <div class="profile-sidebar-menu">

              <router-link tag="a" to="/teacher-cabinet" class="cabinet" :class="{'active': $route.path === '/teacher-cabinet'}">
                {{ $t('sidebar.cabinet') }}
              </router-link>
              <router-link tag="a" to="/teacher-profile" class="profile" :class="{'active': $route.path === '/teacher-profile'}">
                {{ $t('sidebar.profile') }}
              </router-link>
              <router-link tag="a" to="/teacher-experience" class="testing" :class="{'active': $route.path === '/teacher-experience'}">
                {{ $t('experience') }}
              </router-link>
<!--              <router-link tag="a" to="/teacher-olympiad-winners" class="testing" :class="{'active': $route.path === '/teacher-olympiad-winners'}">-->
<!--                {{ $t('olympiad_winners') }}-->
<!--              </router-link>-->
              <router-link tag="a" to="/notification-teacher" class="notifications-teacher"
                           :class="{'active': $route.path === '/notification-teacher'}">
                {{ $t('profile.notifications.title') }}
              </router-link>
              <router-link tag="a" to="/teacher-settings" class="settings" :class="{'active': $route.path === '/teacher-settings'}">
                {{ $t('sidebar.settings') }}
              </router-link>

              <div class="logout-link">
                <ul class="navbar-nav">
                  <li v-if="" class="nav-item">
                    <a @click.prevent="logoutMethod" class="nav-link cursor-pointer">{{ $t('navbar.signout') }}</a>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Vue from "vue";

export default {
  name: 'ProfileNavbarTeacher',
  data() {
    return {
      Questions: {
        it: [],
        creations: [],
        sport: [],
        summerTime: '',
        profession: [],
        country: '',
        sections: '',
        onlineSections: '',
        friendlyTeacher: '',
        friendlyClass: '',
        schoolHelper: '',
        schoolProblems: [],
        schoolDistance: '',
        haveComputer: '',
        internetActivity: [],
        deviceType: '',
        ethernet: '',
        status: '',
        level: '',
        win: '',
        textWin: '',
        textIt: '',
        textCreations: '',
        textSport: '',
        textProfession: ''
      },
      informedCheckbox: false,
      avatar: '',
      user: {},
      showPoleButton: false,
      collapsed: true,
      hasNewNotification: false,
      logged: false,
    }
  },
  mounted() {
    // this.$http.get(window.API_ROOT + '/api/user/edit')
    //     .then((res) => {
    //       this.logged = true;
    //       if (res.data.data.session_id != localStorage.getItem('session_id')) {
    //         this.logout();
    //         localStorage.setItem('session_id', '');
    //         localStorage.setItem('token', '');
    //         localStorage.setItem('user', '');
    //         window.location = '/';
    //       }
    //       this.user = res.body.data;
    //       this.avatar = `${window.API_ROOT}/api/user/avatar?file=${this.user.avatar}`;
    //       sessionStorage.setItem('avatar-url', `${window.API_ROOT}/api/user/avatar?file=${this.user.avatar}`);
    //       const {notifications} = res.body.data.notifications
    //       this.hasNewNotification = this.checkNewNotification(notifications)
    //       this.showPoleButton = res.body.quiz_info == true && res.body.data.polled == 0
    //       this.showPoleButton = false
    //       localStorage.setItem('user', JSON.stringify(res.body.data));
    //       this.Questions.status = 'участник'
    //       if (res.body.data.first_stage_winner) {
    //         this.Questions.status = 'финалист'
    //       }
    //       if (res.body.data.second_stage_winner) {
    //         this.Questions.status = 'победитель'
    //       }
    //
    //       if ($('body').width() <= 992) {
    //         if (this.showPoleButton == true) {
    //           this.$bvModal.show('poledModal-mob')
    //         }
    //       }
    //
    //     })

  },
  watch: {
    'Questions.textWin'() {
      this.Questions.win = '__other_option__'
    },
    informedCheckbox() {
      this.$bvModal.hide('poledModal-mob')
      if (this.$i18n.locale == 'ru') {
        this.$bvModal.show('poledModal-2-ru-mob')
      } else if (this.$i18n.locale == 'kz') {
        this.$bvModal.show('poledModal-2-kz-mob')
      }

    }
  },
  methods: {


    postData(url) {
      let internetActivity = '';
      let schoolProblems = '';
      let profession = '';
      let sport = '';
      let creations = '';
      let it = '';
      let QuestionTextWin = '';
      let request;
      if (this.$i18n.locale == 'ru') {
        if (this.Questions.win == '__other_option__') {
          QuestionTextWin = `&entry.1479163013.other_option_response=${encodeURI(this.Questions.textWin)}`
        }

        for (let i = 0; i < this.Questions.it.length; i++) {
          if (this.Questions.it[i] == '__other_option__') {
            if (this.Questions.textIt) {
              it += `&entry.1331595465.other_option_response=${encodeURI(this.Questions.textIt)}&entry.1331595465=${encodeURI(this.Questions.it[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            it += `&entry.1331595465=${encodeURI(this.Questions.it[i])}`
          }
        }

        for (let i = 0; i < this.Questions.internetActivity.length; i++) {
          internetActivity += `&entry.1709066131=${encodeURI(this.Questions.internetActivity[i])}`
        }

        for (let i = 0; i < this.Questions.schoolProblems.length; i++) {
          schoolProblems += `&entry.2026397148=${encodeURI(this.Questions.schoolProblems[i])}`
        }

        for (let i = 0; i < this.Questions.profession.length; i++) {
          if (this.Questions.profession[i] == '__other_option__') {
            if (this.Questions.textProfession) {
              profession += `&entry.1693994627.other_option_response=${encodeURI(this.Questions.textProfession)}&entry.1693994627=${encodeURI(this.Questions.profession[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            profession += `&entry.1693994627=${encodeURI(this.Questions.profession[i])}`
          }
        }
        for (let i = 0; i < this.Questions.sport.length; i++) {
          if (this.Questions.sport[i] == '__other_option__') {
            if (this.Questions.textSport) {
              sport += `&entry.117665550.other_option_response=${encodeURI(this.Questions.textSport)}&entry.117665550=${encodeURI(this.Questions.sport[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            sport += `&entry.117665550=${encodeURI(this.Questions.sport[i])}`
          }


        }
        for (let i = 0; i < this.Questions.creations.length; i++) {

          if (this.Questions.creations[i] == '__other_option__') {
            if (this.Questions.textCreations) {
              creations += `&entry.723918317.other_option_response=${encodeURI(this.Questions.textCreations)}&entry.723918317=${encodeURI(this.Questions.creations[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            creations += `&entry.723918317=${encodeURI(this.Questions.creations[i])}`
          }
        }
        request = `entry.1837795238=${encodeURI(this.user.iin)}` +
            `&entry.218682015=${encodeURI(this.user.region_name)}` +
            `&entry.681155163=${encodeURI(this.user.locality_name)}` +
            `&entry.1145555405=${encodeURI(this.user.city_name)}` +
            `&entry.1274123360=${encodeURI(this.user.education.region_name)}` +
            `&entry.463274075=${encodeURI(this.user.education.location_name)}` +
            `&entry.1043618168=${encodeURI(this.user.education.city_name)}` +
            `&entry.1938511119=${encodeURI('Школа')}` +
            `&entry.1393679014=${encodeURI(this.user.school)}` +
            `&entry.170288098=${encodeURI(this.user.class)}` +
            `&entry.1143191096=${encodeURI(this.user.first_stage)}` +
            `&entry.496385851_year=${encodeURI(Number(this.user.birth_date.substr(0, 4)))}` +
            `&entry.496385851_month=${encodeURI(Number(this.user.birth_date.substr(5, 2)))}` +
            `&entry.496385851_day=${encodeURI(Number(this.user.birth_date.substr(8, 2)))}` +
            `&entry.1331363301=${encodeURI(this.user.sex == 1 ? 'Муж' : 'Жен')}` +
            `&entry.294793165=${encodeURI(this.user.education.instruction_language == 'ru' ? 'рус' : 'каз')}` +
            `&entry.46179682=${encodeURI(this.user.education.test_language == 'ru' ? 'рус' : 'каз')}` +
            `&entry.2067872883=${encodeURI(this.Questions.status)}` +
            `&entry.1461016368=${encodeURI(this.Questions.level)}` +
            QuestionTextWin +
            `&entry.1479163013=${encodeURI(this.Questions.win)}` +
            `&entry.1550302930=${encodeURI(this.Questions.ethernet)}` +
            `&entry.214357571=${encodeURI(this.Questions.deviceType)}` +
            internetActivity +
            `&entry.1733881976=${encodeURI(this.Questions.haveComputer)}` +
            `&entry.1779434182=${encodeURI(this.Questions.schoolDistance)}` +
            schoolProblems +
            `&entry.98092028=${encodeURI(this.Questions.schoolHelper)}` +
            `&entry.1231476756=${encodeURI(this.Questions.friendlyClass)}` +
            `&entry.1178640644=${encodeURI(this.Questions.friendlyTeacher)}` +
            `&entry.1602905380=${encodeURI(this.Questions.sections)}` +
            `&entry.1029012000=${encodeURI(this.Questions.onlineSections)}` +
            it +
            creations +
            sport +
            profession +
            `&entry.2109720141=${encodeURI(this.Questions.country)}` +
            `&entry.1610799912=${encodeURI(this.Questions.summerTime)}` +
            `&entry.1331363301_sentinel=&entry.294793165_sentinel=&entry.46179682_sentinel=&entry.2067872883_sentinel=&entry.1461016368_sentinel=&entry.1479163013_sentinel=&entry.1550302930_sentinel=&entry.214357571_sentinel=&entry.1709066131_sentinel=&entry.1733881976_sentinel=&entry.1779434182_sentinel=&entry.2026397148_sentinel=&entry.98092028_sentinel=&entry.1231476756_sentinel=&entry.1178640644_sentinel=&entry.1602905380_sentinel=&entry.1029012000_sentinel=&entry.1331595465_sentinel=&entry.723918317_sentinel=&entry.117665550_sentinel=&entry.1610799912_sentinel=&entry.1693994627_sentinel=&entry.2109720141_sentinel=&fvv=1&partialResponse=%5Bnull%2Cnull%2C%226199367058393720432%22%5D&pageHistory=0&fbzx=6199367058393720432`;

      } else if (this.$i18n.locale == 'kz') {
        if (this.Questions.win == '__other_option__') {
          QuestionTextWin = `&entry.1298338927.other_option_response=${encodeURI(this.Questions.textWin)}`
        }

        for (let i = 0; i < this.Questions.it.length; i++) {
          if (this.Questions.it[i] == '__other_option__') {
            if (this.Questions.textIt) {
              it += `&entry.1572485380.other_option_response=${encodeURI(this.Questions.textIt)}&entry.1572485380=${encodeURI(this.Questions.it[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            it += `&entry.1572485380=${encodeURI(this.Questions.it[i])}`
          }
        }

        for (let i = 0; i < this.Questions.internetActivity.length; i++) {
          internetActivity += `&entry.1831503231=${encodeURI(this.Questions.internetActivity[i])}`
        }

        for (let i = 0; i < this.Questions.schoolProblems.length; i++) {
          schoolProblems += `&entry.1934317436=${encodeURI(this.Questions.schoolProblems[i])}`
        }

        for (let i = 0; i < this.Questions.profession.length; i++) {
          if (this.Questions.profession[i] == '__other_option__') {
            if (this.Questions.textProfession) {
              profession += `&entry.1751799059.other_option_response=${encodeURI(this.Questions.textProfession)}&entry.1751799059=${encodeURI(this.Questions.profession[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            profession += `&entry.1751799059=${encodeURI(this.Questions.profession[i])}`
          }
        }

        for (let i = 0; i < this.Questions.sport.length; i++) {
          if (this.Questions.sport[i] == '__other_option__') {
            if (this.Questions.textSport) {
              sport += `&entry.436935992.other_option_response=${encodeURI(this.Questions.textSport)}&entry.436935992=${encodeURI(this.Questions.sport[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            sport += `&entry.436935992=${encodeURI(this.Questions.sport[i])}`
          }


        }
        for (let i = 0; i < this.Questions.creations.length; i++) {

          if (this.Questions.creations[i] == '__other_option__') {
            if (this.Questions.textCreations) {
              creations += `&entry.1098426865.other_option_response=${encodeURI(this.Questions.textCreations)}&entry.1098426865=${encodeURI(this.Questions.creations[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            creations += `&entry.1098426865=${encodeURI(this.Questions.creations[i])}`
          }
        }
        request = `entry.700033346=${encodeURI(this.user.iin)}` +
            `&entry.693013719=${encodeURI(this.user.region_name)}` +
            `&entry.390634861=${encodeURI(this.user.locality_name)}` +
            `&entry.82949384=${encodeURI(this.user.city_name)}` +
            `&entry.348920338=${encodeURI(this.user.education.region_name)}` +
            `&entry.1538933299=${encodeURI(this.user.education.location_name)}` +
            `&entry.1770747759=${encodeURI(this.user.education.city_name)}` +
            `&entry.170788498=${encodeURI('Мектеп')}` +
            `&entry.250945860=${encodeURI(this.user.school)}` +
            `&entry.718510597=${encodeURI(this.user.class)}` +
            `&entry.389593988=${encodeURI(this.user.first_stage)}` +
            `&entry.1372016661_year=${encodeURI(Number(this.user.birth_date.substr(0, 4)))}` +
            `&entry.1372016661_month=${encodeURI(Number(this.user.birth_date.substr(5, 2)))}` +
            `&entry.1372016661_day=${encodeURI(Number(this.user.birth_date.substr(8, 2)))}` +
            `&entry.570574830=${encodeURI(this.user.sex == 1 ? 'Муж' : 'Жен')}` +
            `&entry.1376425566=${encodeURI(this.user.education.instruction_language == 'ru' ? 'рус' : 'каз')}` +
            `&entry.1489292218=${encodeURI(this.user.education.test_language == 'ru' ? 'рус' : 'каз')}` +
            `&entry.589168218=${encodeURI(this.Questions.status)}` +
            `&entry.122697157=${encodeURI(this.Questions.level)}` +
            QuestionTextWin +
            `&entry.1298338927=${encodeURI(this.Questions.win)}` +
            `&entry.566726131=${encodeURI(this.Questions.ethernet)}` +
            `&entry.316171337=${encodeURI(this.Questions.deviceType)}` +
            internetActivity +
            `&entry.1685561511=${encodeURI(this.Questions.haveComputer)}` +
            `&entry.78617162=${encodeURI(this.Questions.schoolDistance)}` +
            schoolProblems +
            `&entry.1061289283=${encodeURI(this.Questions.schoolHelper)}` +
            `&entry.896011520=${encodeURI(this.Questions.friendlyClass)}` +
            `&entry.1954730864=${encodeURI(this.Questions.friendlyTeacher)}` +
            `&entry.1019001030=${encodeURI(this.Questions.sections)}` +
            `&entry.1491423878=${encodeURI(this.Questions.onlineSections)}` +
            it +
            creations +
            sport +
            profession +
            `&entry.165211079=${encodeURI(this.Questions.country)}` +
            `&entry.1782540906=${encodeURI(this.Questions.summerTime)}`
      }


      fetch(url, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },

        body: request


      })
      Vue.toastr({
        message: this.$t('pole-modal.success'),
        description: this.$t('pole-modal.success-survey'),
        type: 'success'
      })
      this.sendPoleModal()
      if (this.$i18n.locale == 'ru') {
        this.$bvModal.hide('poledModal-2-ru-mob')
      } else if (this.$i18n.locale == 'kz') {
        this.$bvModal.hide('poledModal-2-kz-mob')
      }
      this.showPoleButton = false


    },
    sendForm() {
      let url;
      let form = JSON.stringify(this.Questions)
      form = JSON.parse(form)
      delete form.textWin
      delete form.textIt
      delete form.textCreations
      delete form.textSport
      delete form.textProfession

      function checkElement(el) {
        return el != '';
      }

      if (Object.values(form).every(checkElement) && this.Questions.it.length <= 3 && this.Questions.creations.length <= 3 && this.Questions.sport.length <= 3 && this.Questions.profession.length <= 3 && this.Questions.schoolProblems.length <= 3 && this.Questions.internetActivity.length <= 3) {
        if (this.$i18n.locale == 'ru') {
          url = 'https://docs.google.com/forms/d/e/1FAIpQLScJLL9eiKjYFnaIfUXaItQLWK294RUQ3fr1LZrNYva4SUsz-A/formResponse'
        } else if (this.$i18n.locale == 'kz') {
          url = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSczPOYJ7GG2qsD_JP9NkgSKr93WbT7xJqjXE8xcLZBNuoWUAA/formResponse'
        }

        this.postData(url);
      } else {
        Vue.toastr({
          message: this.$t('pole-modal.warning'),
          description: this.$t('pole-modal.required'),
          type: 'error'
        })
      }
    },
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      this.$router.go(this.$router.currentRoute);
    },
    logoutMethod() {
      localStorage.setItem('session_id', '');
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      window.location = '/';
      this.logout()
    },
    clickaway() {
      this.collapsed = true
    },
    checkNewNotification(notifications) {
      if (Array.isArray(notifications)) {
        for (let i = 0; i < notifications.length; i++) {
          if (notifications[i].is_viewed === 0) {
            return true
          }
        }
        return false
      }
    },
    ...mapActions({
      'logout': 'logout'
    })
  }
}
</script>
<style lang="css">
.el-checkbox {
  text-align: left !important;
  display: flex !important;
  margin-right: 0;
}

@media (max-width: 500px) {
  .modal-dialog {
    margin: 0;
  }

  .close {
    right: 0rem !important;
    top: 0rem !important;
  }

  .modal-body {
    padding: 0.5rem !important;
  }

  .el-checkbox {
    text-align: left !important;
    display: flex !important;
    margin-right: 0;
  }

  .confirm-pole-modal {
    margin-top: 3rem;
    margin-bottom: 6rem;
    font-size: 16px !important;
  }
}

.confirm-pole-modal {
  margin-top: 1rem;
  text-align: center !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #2C2C2C !important;
}

.confirm-pole-modal span {
  text-align: center !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #2C2C2C !important;
}

.pole-modal-content {
  display: flex !important;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}


</style>
<style lang="css" scoped>

.el-checkbox-group {
  display: flex;
  flex-direction: column;
}

.modal #dialog div {
  position: relative;
  z-index: 1;
  width: 100%;
}

.el-radio, .el-radio__input {
  white-space: unset;
}

.el-checkbox, .el-checkbox__input {
  text-align: initial;

}

.title-polemodal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  color: #05458C;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pole-modal-title {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;

  color: #05458C;

}

.el-radio {
  display: flex;
}

.el-radio-group {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.el-checkbox-group {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.error-pole-modal {
  width: 100% !important;
  color: red;
  border-bottom: 1px solid red;
  padding-bottom: 10px;
}
</style>
<style>
.el-dropdown-link {
  text-transform: uppercase;
}

.profile-navbar-mobile {
  display: none;
}

.btn-back-menu {
  border: none;
  background-color: transparent;
}


@media screen and (max-width: 992px) {
  .profile-navbar-mobile {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .menu-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    width: 45px;
    height: 45px;
    background: white;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 66px;
  }

  .nav-logo {
    width: 70px;
  }

  .profile-navbar-site {
    display: none;
  }

  .sideBarMobile {
    display: block;
    border-radius: 12px 0px 0px 12px;
  }

  .w3-container {
    position: absolute;
    right: -38px;
    top: 48px;
    margin-bottom: 10px;
  }

  .modal-menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
  }

  .content .profile-navbar .nav-link {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    font-feature-settings: 'tnum' on, 'lnum' on;

    color: #707C97;
    padding: 0.5rem 2rem;
  }

  .closeIcon {
    position: relative;
    right: 40px;
    top: 15px;
  }

}

@media screen and (max-width: 370px) {
  .menu-block {
    margin-left: 4px;
  }
}
</style>
<style lang="css" scoped>
.el-radio, .el-radio__input {
  white-space: unset;
}

.el-checkbox, .el-checkbox__input {
  white-space: unset;
}

.title-polemodal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #05458C;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pole-modal-title {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;

  color: #05458C;

}

.el-radio {
  display: flex;
}

.el-radio-group {
  width: fit-content;
  display: flex;
  flex-direction: column;
}
</style>

